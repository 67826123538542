<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 15.5C13.933 15.5 15.5 13.933 15.5 12C15.5 10.067 13.933 8.5 12 8.5C10.067 8.5 8.5 10.067 8.5 12C8.5 13.933 10.067 15.5 12 15.5Z"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <path
      d="M20.9327 17C21.6154 15.6474 22 14.1186 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 14.1186 2.38456 15.6474 3.06729 17"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <path
      d="M19 19.5397C16.8784 18.5524 14.5051 18 12 18C9.49494 18 7.1216 18.5524 5 19.5397V22H19V19.5397Z"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>
</template>
